import React, {useRef, useState, useCallback} from 'react';
import {Box, IconButton, useTheme} from '@mui/material';
import {Typography, Grid, Paper} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {AiFillStar, AiOutlineStar} from 'react-icons/ai';
import {ImPause, ImPlay2} from 'react-icons/im';
import Rating from '@mui/material/Rating';
import {formatNumber} from '@/common/utils/formatters';
import TwitterProfileEmbed from './TwitterProfileEmbed';
import {flags, resolveImageUrl} from '../config';
import TwitterFollow from './TwitterFollowEmbed';
import CustomTwitterFollow from './CustomTwitterFollow';
import ReactAudioPlayer from 'react-audio-player';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {FaEllipsisV} from 'react-icons/fa';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {appRoutes} from '@/common/config';
import {useRouter} from 'next/router';

import ClickAwayListener from '@mui/material/ClickAwayListener';

library.add(fas, far);

interface Badge {
  name: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
}
interface ListingBoxProps {
  id: number;
  name: string;
  facebookProfile?: string;
  instagramProfile?: string;
  tiktokProfile?: string;
  podcastURL?: string;
  twitterHandle: string;
  twitterFollowers: number;
  threadsProfile?: string;
  goodreadsProfile?: string;
  mailingListProfile?: string;
  reviewCount: number;
  rating: number;
  badges: Badge[];
  hasCurrentUserReviewed: boolean;
  showClaimButton: boolean;
  thumbnailUrl?: string;
  productThumbnailUrl?: string;
  productUrl?: string;
  productAudioUrl?: string;
  onClickRate: (listingId: number) => void | Promise<void>;
  onClickClaim: (listingId: number) => void | Promise<void>;
}

export default function ListingBox({
  id,
  name,
  facebookProfile,
  instagramProfile,
  tiktokProfile,
  podcastURL,
  twitterHandle,
  twitterFollowers,
  threadsProfile,
  goodreadsProfile,
  mailingListProfile,
  reviewCount,
  rating,
  badges,
  hasCurrentUserReviewed,
  showClaimButton,
  thumbnailUrl,
  productThumbnailUrl,
  productUrl,
  productAudioUrl,
  onClickRate,
  onClickClaim,
}: ListingBoxProps) {
  const theme = useTheme();
  const audioRef = useRef<ReactAudioPlayer | null>(null);
  const [isPaused, setIsPaused] = useState(true);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  const handleClickRate = () => {
    setAnchorEl(null);
    onClickRate(id);
  };
  // const handleClickClaim = () => {
  //   onClickClaim(id);
  // };
  const handleToggleAudio = () => {
    const audio = audioRef.current?.audioEl.current;

    if (!audio) {
      return;
    }

    if (audio.paused) {
      audio.play();
      setIsPaused(false);
    } else {
      audio.pause();
      setIsPaused(true);
    }
  };
  const AudioIcon = isPaused ? ImPlay2 : ImPause;

  const handleClose = () => {
    setAnchorEl(null);
    setIsMouseInside(false);
  };
  const {push} = useRouter();

  const handleOnSelect = useCallback(
    (listingId: number) => {
      push(appRoutes.static.listmageProfile(listingId));
    },
    [push],
  );
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Paper
        sx={{
          p: 2,
          m: 1,
          position: 'relative',
        }}
        onClick={() => setIsMouseInside(true)}
        onMouseEnter={() => setIsMouseInside(true)}
        onMouseLeave={handleClose}
      >
        {isMouseInside && (
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              m: 1,
              p: 1,
              width: '32px',
            }}
            onClick={(event: any) =>
              !anchorEl ? setAnchorEl(event.currentTarget) : setAnchorEl(null)
            }
          >
            <FaEllipsisV color="#ffffff60" style={{height: '16px'}} />
          </IconButton>
        )}

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            mt: '48px',
          }}
        >
          <MenuItem onClick={handleClickRate}>Rate this Listing</MenuItem>
        </Menu>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <TwitterProfileEmbed
            id="mouse-over-popover-twiiter-profile-embed"
            twitterHandle={twitterHandle}
            render={(openEmbed, isOpen) => (
              <>
                <Box marginRight={1}>
                  <img
                    title={name}
                    src={thumbnailUrl ?? '/placeholder-user.png'}
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    display: 'inline-block',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  aria-owns={isOpen ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  // These showed the twitter profile information, which Twitter broke.
                  // onMouseEnter={openEmbed}
                  onClick={() => {
                    handleOnSelect(id);
                  }}

                  // noWrap
                >
                  {name}
                </Typography>
                {productThumbnailUrl && (
                  <Box marginLeft={1}>
                    <a target="_blank" href={productUrl} rel="noreferrer">
                      <img
                        title={productUrl}
                        src={productThumbnailUrl}
                        style={{
                          borderRadius: '4px',
                          width: '48px',
                          height: '48px',
                        }}
                      />
                    </a>
                  </Box>
                )}
              </>
            )}
          />
          {isClient &&
            badges?.map(badge => (
              <Box key={badge.name} display="flex" marginLeft={1}>
                {flags.ENABLE_BADGE_IMAGE && badge.imageId && (
                  <img
                    title={badge.name}
                    src={resolveImageUrl(badge.imageId)}
                    style={{
                      width: '48px',
                      height: '48px',
                    }}
                    onClick={() => push(appRoutes.home.badges(`${badge.name}`))}
                  />
                )}
                {flags.ENABLE_BADGE_ICON && badge.iconName && (
                  <Tooltip title={badge.name}>
                    <div>
                      <FontAwesomeIcon
                        size="2x"
                        color={badge.iconColor}
                        icon={badge.iconName as IconProp}
                      />
                    </div>
                  </Tooltip>
                )}
              </Box>
            ))}
          {isClient && productAudioUrl && (
            <div>
              <Tooltip title="Play Audio Sample">
                <IconButton onClick={handleToggleAudio}>
                  <AudioIcon size={32} color={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              <ReactAudioPlayer
                src={productAudioUrl}
                preload="none"
                onEnded={() => {
                  setIsPaused(true);
                }}
                ref={element => {
                  audioRef.current = element;
                }}
              />
            </div>
          )}
        </Box>
        <Grid container alignItems="center">
          <Rating
            readOnly
            name="Rating"
            value={rating}
            size="small"
            precision={0.5}
            icon={
              <AiFillStar
                color={theme.palette.primary.main}
                fontSize="inherit"
              />
            }
            emptyIcon={<AiOutlineStar fontSize="inherit" />}
          />
          <Typography variant="subtitle2" sx={{ml: 1}}>
            ({formatNumber(reviewCount)})
          </Typography>
          {/* {flags.ENABLE_CLAIM_LISTING && showClaimButton && (
          <Button
            onClick={handleClickClaim}
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none', ml: 2 }}
          >
            Claim
          </Button>
        )} */}
        </Grid>

        <Box marginTop={1}>
          {flags.ENABLE_3RD_PARTY_TWITTER_FOLLOW ? (
            <TwitterFollow handle={twitterHandle} />
          ) : (
            <CustomTwitterFollow
              handle={twitterHandle}
              followers={twitterFollowers}
            />
          )}
        </Box>

        {/* <TwitterHashtagButton tag={'cybersecurity'} /> */}
      </Paper>
    </ClickAwayListener>
  );
}
