import { TwitterFollowButton } from 'react-twitter-embed';
import { Skeleton } from '@mui/lab';
import React from 'react';

interface TwitterFollowProps {
  handle: string;
}

export default function TwitterFollow({ handle }: TwitterFollowProps) {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <>
      {!loaded && <Skeleton variant="rectangular" width="100%" height={20} />}
      <div style={{ display: loaded ? 'block' : 'none' }}>
        <TwitterFollowButton
          screenName={handle}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </>
  );
}
