export function formatNumber(value: number | string) {
  const val = typeof value === 'string' ? parseFloat(value) : value;

  return val.toLocaleString();
}


export const formatInputTwitter = (text: string) => {
  if (!text) return '';
  return text.replace('@', '').replace('#', '');
};

