import * as Sentry from '@sentry/nextjs';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  ApiError,
  ListingClaimResponse,
  ListingClaimVars,
  MyListingResponse,
} from '@/common/api/types';
import { useApiContext } from '@/common/contexts/ApiContext';
import { SimpleAuthDialog } from '@/common/components/SimpleAuthDialog';
import { queries } from '@/common/api/operations';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useGlobalContext } from '@/common/contexts/GlobalContext';;


interface ListingClaimDialogProps {
  twitterHandle: string;
  isOpen: boolean;
  onClose: () => void;
  onClaimCompleted?: (listingId: number) => void;
}

type UseClaimParams = ListingClaimDialogProps;

function useClaim({
  isOpen,
  twitterHandle,
  onClose,
  onClaimCompleted,
}: UseClaimParams) {
  const { siteName, siteURL } = useGlobalContext()
  const { apiClient } = useApiContext();
  const claimMutation = useMutation<
    ListingClaimResponse,
    ApiError | Error,
    ListingClaimVars
  >(apiClient.listings.claim, {
    onSuccess,
    onError,
  });
  const myListingQuery = useQuery(
    queries.auth.myListing,
    apiClient.users.myListing,
    {
      enabled: isOpen,
      refetchInterval: 5 * 1000,
      onSuccess: onMyListingSuccess,
    },
  );
  const { enqueueSnackbar } = useSnackbar();

  function onMyListingSuccess(response: MyListingResponse) {
    if (response.listingId && onClaimCompleted) {
      onClaimCompleted(response.listingId);
      onClose();
    }
  }

  function onSuccess(item: ListingClaimResponse) {
    // TODO: update cache for all other queries that contain listings
    myListingQuery.refetch();
    enqueueSnackbar('Your claim was submitted', { variant: 'success' });
    window.open(
      queryString.stringifyUrl({
        url: 'https://twitter.com/intent/tweet',
        query: {
          text: `I can be found on ${siteName} ${siteURL} #${siteName} #${item.secret}`,
        },
      }),
    );
  }

  function onError(error: ApiError | Error) {
    enqueueSnackbar(error.message, { variant: 'error' });
    Sentry.captureException(error);
  }

  const handleConfirmListing = () => {
    claimMutation.mutate({ twitterHandle });
  };

  return {
    handleConfirmListing,
    listingClaims: myListingQuery.data?.listingClaims ?? [],
  };
}

export default function ListingClaimDialog({
  twitterHandle,
  isOpen,
  onClose,
  onClaimCompleted,
}: ListingClaimDialogProps) {

  const { siteName } = useGlobalContext()
  const { contactEmail } = useGlobalContext()
  const { handleConfirmListing, listingClaims } = useClaim({
    isOpen,
    onClose,
    twitterHandle,
    onClaimCompleted,
  });

  return (
    <SimpleAuthDialog
      id="claim-dialog"
      title="Claim this listing"
      confirmLabel="Claim"
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={handleConfirmListing}
    >
      <Box>
        {/* <Typography>
          Claiming a listing is done by veryfing that you have control over the
          twitter handle associated to the listing. In order for <b>{siteName}</b>{' '}
          to verify this ownership, it is necessary that you publish a specially
          crafted tweet that contains a specific hashtag. <b>{siteName}</b> can
          then verify that the tweet was published and authorize the claim to
          the listing.
        </Typography>
        <br /> */}
        <Typography>
          Claiming a listing is done by verifying that you have control over the
          twitter handle associated to the listing. In order for <b>{siteName}</b>{' '}
          to verify this ownership, it is necessary that you publish a specially
          crafted tweet that contains a specific hashtag. <b>{siteName}</b> can
          then verify that the tweet was published and authorize the claim to
          the listing. If you have any problems, please email <b>{contactEmail}</b>
          and we will verify your identity manually.
        </Typography>
        <br />
        <Typography>
          You can start the process by clicking the Claim button below. You will
          be taken to twitter.com so that you can publish a tweet with a hashtag
          that will be automatically provided for you. It might take up to 24
          hours for the verification to complete.If you have any problems,
          please email <b>{contactEmail}</b> and we will verify your identity manually.
        </Typography>
        <br />
        <Typography>
          You are about to start the process to claim the following listing:
          <ul>
            <li>
              <b>{twitterHandle}</b>
            </li>
          </ul>
        </Typography>
        <br />
        {listingClaims.length > 0 && (
          <>
            <Typography>
              You have already started a claim for the listing below. You can
              still claim a new one if you need to. This window will close
              automatically when the verification is complete.
              <ul>
                {listingClaims.map(claim => (
                  <li key={claim.id}>
                    <b>{claim.twitterHandle}</b>
                  </li>
                ))}
              </ul>
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <CircularProgress />
            </Box>
          </>
        )}
      </Box>
    </SimpleAuthDialog>
  );
}
