import {Query, QueryClient} from 'react-query';

interface UpdateQueryCacheParams<Response> {
  queryName: string | unknown[];
  getNewCache: (
    cache: Response,
    query: Query<Response>,
    allQueries: Query<Response>[],
  ) => Response | null;
  queryClient: QueryClient;
}

export function updateQueryCache<Response>({
  queryName,
  getNewCache,
  queryClient,
}: UpdateQueryCacheParams<Response>) {
  const queryCache = queryClient.getQueryCache();
  const allQueries = queryCache.findAll(queryName) as Query<Response>[];

  for (const query of allQueries) {
    const {data} = query.state;

    if (!data) {
      continue;
    }

    const newCache = getNewCache(data, query, allQueries);

    if (newCache === null) {
      continue;
    }

    query.setData(newCache);
  }
}

export function addItemToFirstCache<TResponse, TItem>(
  field: keyof TResponse,
  item: TItem,
) {
  return (
    cache: TResponse,
    query: Query<TResponse>,
    allQueries: Query<TResponse>[],
  ): TResponse | null => {
    const isFirstCache = allQueries.indexOf(query) === 0;

    if (isFirstCache) {
      return {
        ...cache,
        // TODO: how to write this? field should map to a key that is an array
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [field]: [item, ...cache[field]],
      };
    }

    return null;
  };
}

export function replaceItemInCache<
  TResponse,
  TItem extends {id: number | string},
>(key: keyof TResponse, item: TItem) {
  return (
    cache: TResponse,
    query: Query<TResponse>,
    allQueries: Query<TResponse>[],
  ): TResponse | null => {
    // TODO: how to write this? field should map to a key that is an array
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const field = cache[key] as {id: number}[];
    const match = field.find(next => next.id === item.id);

    if (!match) {
      return null;
    }

    return {
      ...cache,
      [key]: field.map(next => {
        if (next.id !== item.id) {
          return next;
        }

        return item;
      }),
    };
  };
}

export function transformItemInCache<TResponse, TItem>(
  key: keyof TResponse,
  itemId: number,
  transformItem: (item: TItem) => TItem,
) {
  return (
    cache: TResponse,
    query: Query<TResponse>,
    allQueries: Query<TResponse>[],
  ): TResponse | null => {
    // TODO: how to write this? field should map to a key that is an array
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const field = cache[key] as {id: number}[];
    const match = field.find(next => next.id === itemId);

    if (!match) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const item = transformItem(match as TItem);

    return {
      ...cache,
      [key]: field.map(next => {
        if (next.id !== itemId) {
          return next;
        }

        return item;
      }),
    };
  };
}
