import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomLink from '../components/CustomLink';
import { appRoutes } from '../config';
import { useGlobalContext } from '@/common/contexts/GlobalContext';

interface HeaderBarProps { }

export default function HeaderBar(props: HeaderBarProps) {
  const { copyrightName } = useGlobalContext()

  return (
    <>
      <Box sx={{
        background: '#24262D',
        padding: theme => theme.spacing(0, 2),
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <Box marginTop={6}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Contact</Typography>
              <CustomLink href={appRoutes.static.aboutUs} text="About Us" />
              <CustomLink href={appRoutes.static.contactUs} text="Contact Us" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Information</Typography>
              <CustomLink
                href={appRoutes.static.cookiesPolicy}
                text="Cookies Policy"
              />
              <CustomLink
                href={appRoutes.static.privacyPolicy}
                text="Privacy Policy"
              />
              <CustomLink
                href={appRoutes.static.contentPolicy}
                text="Content Policy"
              />
              <CustomLink href={appRoutes.static.faq} text="FAQs" />
              <CustomLink href={appRoutes.static.knowledgeBase()} text="Knowledge Base" />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box marginBottom={1}>
              <Typography align="center">
                © {new Date().getFullYear()} Copyright {copyrightName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
