import {useEffect, useState} from 'react';

export default function useLoading(realLoading: boolean) {
  const [loading, setLoading] = useState(realLoading);

  useEffect(() => {
    if (realLoading) {
      setLoading(true);
    } else {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [realLoading]);

  return loading;
}
