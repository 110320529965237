import { Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

interface CustomLinkProps {
  href?: string;
  text?: string;
  isExternal?: boolean;
}

export default function CustomLink({
  text,
  href = '',
  isExternal = false,
}: CustomLinkProps) {
  return isExternal ? (
    <a href={href} target="_blank" rel="noreferrer">
      <Typography variant="body1" sx={{
        color: 'primary.main',
        cursor: 'pointer',
        textDecoration: 'underline',
      }}>
        {text}
      </Typography>
    </a>
  ) : (
    <Link href={href}>
      <Typography variant="body1" sx={{
        color: 'primary.main',
        cursor: 'pointer',
        textDecoration: 'underline',
      }}>
        {text}
      </Typography>
    </Link>
  );
}
