import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

interface CustomTwitterFollowProps {
  handle: string;
  followers?: number;
}

export default function CustomTwitterFollow({
  handle,
  followers,
}: CustomTwitterFollowProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}>
      {/* <div
        onClick={() => {
          window.open(
            stringifyUrl({
              url: 'https://twitter.com/intent/follow',
              query: {
                screen_name: handle,
              },
            }),
          );
        }}
        className={classes.twitterFollow}
      >
        <AiOutlineTwitter
          className={classes.twitterFollowIcon}
          color={theme.palette.common.white}
          size={14}
        />
        Follow @{handle}
      </div>
      {Boolean(followers) && (
        <div className={classes.tooltip}>
          {formatShortCount(followers!, isDesktop ? ' followers' : '')}
        </div>
      )} */}
    </Box>
  );
}
