import Typography from '@mui/material/Typography';
import { SimpleDialog, SimpleDialogProps } from './SimpleDialog';
import { useUserContext } from '../contexts/UserContext';
import React from 'react';
import { useRouter } from 'next/router';
import { appRoutes } from '../config';

export function SimpleAuthDialog({ children, ...props }: SimpleDialogProps) {
  const { isLoggedIn } = useUserContext();
  const { push, pathname } = useRouter();

  const realProps = isLoggedIn
    ? props
    : ({
      ...props,
      title: 'Please log in',
      onConfirm: () => push(appRoutes.auth.login(pathname)),
      confirmLabel: 'Log in',
      cancelLabel: 'Cancel',
    } as Omit<SimpleDialogProps, 'children'>);

  return (
    <SimpleDialog {...realProps}>
      {isLoggedIn ? (
        children
      ) : (
        <Typography>You must login in order to continue</Typography>
      )}
    </SimpleDialog>
  );
}
