import {QueryClient, useQuery} from 'react-query';
import {queries} from '@/common/api/operations';
import {ApiClient, useApiContext} from '../contexts/ApiContext';

export function useMenuItems() {
  const {apiClient} = useApiContext();
  const menuItemsResult = useQuery(
    queries.search.menuItems,
    apiClient.search.menuItems,
  );
  const menuItems: any = menuItemsResult.data?.menuItems ?? [];

  return {
    menuItems,
    allListings: menuItems.flatMap((item: any) => item.topListings ?? []),
  };
}

export async function ssrMenuItems(
  queryClient: QueryClient,
  apiClient: ApiClient,
) {
  await queryClient.prefetchQuery(
    queries.search.menuItems,
    apiClient.search.menuItems,
  );
}
