import Box from '@mui/material/Box';

interface DivisionProps {
  size?: 'small' | 'medium' | 'large';
}

export default function Division({ size = 'medium' }: DivisionProps) {
  const height = {
    large: '60px',
    medium: '40px',
    small: '16px',
  }[size];

  return <Box sx={{ height }} />;
}

